var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("i-header", {
        attrs: {
          theme: "light",
          showBack: !_vm.$route.query.fromWx || this.isWx(),
        },
        on: { "back-event": _vm.backPage },
        scopedSlots: _vm._u([
          {
            key: "center",
            fn: function () {
              return [_vm._v("选择城市")]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "cnt-box" },
        [
          _c("div", { staticClass: "SplitLine" }),
          _c("i-field", {
            attrs: {
              clickable: "",
              readonly: "",
              label: _vm.fieldItem.label,
              placeholder: _vm.fieldItem.placeholder,
              rules: _vm.fieldItem.rules,
            },
            on: {
              click: function ($event) {
                _vm.show = true
              },
            },
            scopedSlots: _vm._u(
              [
                _vm.fieldItem.showLeftIcon
                  ? {
                      key: "left-icon",
                      fn: function () {
                        return [
                          _c("i-icon", {
                            attrs: {
                              name: "icon-address",
                              color: "var(--themeColor)",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
              ],
              null,
              true
            ),
            model: {
              value: _vm.fieldValue,
              callback: function ($$v) {
                _vm.fieldValue = $$v
              },
              expression: "fieldValue",
            },
          }),
          _c("div", { staticClass: "SplitLine" }),
          _c(
            "div",
            { staticClass: "appChooseCityBtn" },
            [
              _c(
                "i-button",
                {
                  attrs: {
                    block: "",
                    round: "",
                    type: "primary",
                    size: "normal",
                  },
                  on: { click: _vm.openWebapp },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$store.state.webappAlias.searchBtnTxt || "开始搜索"
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c("iSelectCity", {
            attrs: {
              cityList: _vm.cityList,
              visible: _vm.show,
              txt: "txt",
              isH5: _vm.isH5,
            },
            on: {
              onChosen: _vm.chooseCity,
              visibleListener: _vm.visibleListener,
            },
          }),
        ],
        1
      ),
      _c("ItemPop", {
        ref: "itemPop",
        attrs: {
          tips: _vm.mealRules.xiaomishuTips,
          itemList: _vm.mealRules.takeOutList,
        },
        on: { handlechannel: _vm.handlechannel },
      }),
      _vm.goWxShow
        ? _c("div", { staticClass: "WXShadow" }, [
            _c("img", { attrs: { src: require("../assets/img/jiantou.png") } }),
            _c("div", { staticClass: "shadowtext" }, [
              _c("div", [_vm._v("温馨提示")]),
              _c("span", [_vm._v("请在外部浏览器中打开")]),
              _c(
                "a",
                {
                  on: {
                    click: function ($event) {
                      _vm.goWxShow = false
                    },
                  },
                },
                [_vm._v("我知道了")]
              ),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }