<template>
    <div>
        <i-header @back-event="backPage" theme="light" :showBack="!$route.query.fromWx || this.isWx()">
            <template v-slot:center>选择城市</template>
        </i-header>
        <div class="cnt-box">
            <!--            <Field :fieldList="fieldList" @call-back="openComponent('city')"/>-->
            <div class="SplitLine"></div>
            <i-field
                v-model="fieldValue"
                clickable
                readonly
                :label="fieldItem.label"
                :placeholder="fieldItem.placeholder"
                :rules="fieldItem.rules"
                @click="show = true"
            >
                <template #left-icon v-if="fieldItem.showLeftIcon">
                    <i-icon name="icon-address" color="var(--themeColor)"></i-icon>
                </template>
            </i-field>
            <div class="SplitLine"></div>


            <div class="appChooseCityBtn">
                <i-button block round type="primary" size="normal" @click="openWebapp">
                    {{ $store.state.webappAlias.searchBtnTxt || "开始搜索" }}
                </i-button>
            </div>

            <iSelectCity
                @onChosen="chooseCity"
                @visibleListener="visibleListener"
                :cityList="cityList"
                :visible="show"
                txt="txt"
                :isH5="isH5"
            />
        </div>
        <ItemPop :tips="mealRules.xiaomishuTips" ref="itemPop" @handlechannel="handlechannel" :itemList="mealRules.takeOutList" />
        <div class="WXShadow" v-if="goWxShow">
            <img src="../assets/img/jiantou.png" />
            <div class="shadowtext">
                <div>温馨提示</div>
                <span>请在外部浏览器中打开</span>
                <a @click="goWxShow = false">我知道了</a>
            </div>
        </div>
    </div>
</template>

<script>
import iJsBridge from "smart-javascript-bridge";
import * as tinyCookie from "tiny-cookie";
import ItemPop from "@/components/ItemPop/index.vue";
export default {
    name: "chooseCity",
    components: {
        ItemPop
    },
    data() {
        return {
            nowCity: {},
            cityList: [],
            fieldItem: {
                controlName: "eventCity",
                label: "城市",
                params: {keyCode: "CPC-Province,CPC-City"},
                placeholder: "请选择城市",
                rules: [{required: true, message: "请选择城市"}],
                type: "iCascaderRest",
                url: "/foundation/dict/IDictService/QueryTreeAsync",
                showLeftIcon: true,
            },
            show: false,
            fieldValue: "",
            isH5: this.$cookie.get("container") == "h5" ? true : false,
            mealRules: {
                takeOutList:[
                    {
                        "txt": "小秘书",
                        "icon": "icondingcanxiaomishu",
                        "show": true,
                        "itemType": "xiaomishu",
                        "type": "takeOut"
                    },
                ]
            },
            goWxShow: false
        };
    },
    async created() {
        this.getCity();
        if(this.$route.query.fromWx && !this.isWx()){
            this.chooseCity(JSON.parse(this.$route.query.address))
            var params = {
                "ItemId": this.generateUUID(),
                "Amount": 0.01,
                "Sid": this.mealRules.sid,
                "ItemType": 7
            }
            var elemeURL = await this.$service.common('/rpc/pay/Alipay/GenerateReadOnlyAliTakeoutUrl', params)
            this.$idialog
                .confirm({
                title: "提示",
                message:
                    "请点击确认按钮跳转",
                })
                .then(() => {
                    window.location.href = elemeURL.content
                });

        }
    },
    methods: {
        visibleListener() {
            this.show = false
        },
        getCity() {
            let params = {
                type: 2,
            };
            this.$service.QueryRegion(params).then((res) => {
                this.cityList = res.content || res;
            });
        },
        openComponent(type) {
            this.$publicFun.openPopChoose(this, type);
        },
        chooseCity(e) {
            this.nowCity = e;
            this.fieldValue = e.txt;
            this.show = false;
        },
        //打开webview
        async openWebapp() {
            if (!this.nowCity.txt) {
                this.$itoast("请先选择城市！");
                return;
            }
            let appConf = {
                webAppAlias: this.$route.query.webAppAlias,
                path: this.$route.query.path
            };
            let appData = {
                paramCode: {
                    city: this.fieldValue || '',
                    search: true,
                    latitude: this.nowCity.latitude || '',
                    longitude: this.nowCity.longitude || ''
                },
                isFromMore: true,
                tenant_code: tinyCookie.getCookie("tenant_code"),
                token: tinyCookie.getCookie("token"),
            };
            if(this.$route.query.webAppAlias == 'webapp-supermarket'){
                this.openMartke()
                return
            }
            if(this.$route.query.webAppAlias === 'webapp-imeal'){
                this.openImeal(appData, appConf)
                return
            }
            if(this.$route.query.webAppAlias != 'webapp-iwaimai'){
                await this.$publicFun.openWebAppCate(appData, appConf);
                setTimeout(() => {
                    this.backPage();
                },300)
                return
            }

            let mealRules = await this.getMealRules()
            if(mealRules.takeOutNew){
                this.mealRules = mealRules
                this.$refs.itemPop.open();
                return
            }
            let optionalType = await this.getWaimaiType()
            if(optionalType.includes('饿了么H5') && optionalType.length == 1){
                let eleH5params = {
                    itemId: this.generateUUID(),
                    UserPhone: this.$store.state.userMsg.phoneNumber,
                    Longitude: this.nowCity.longitude || '',
                    Latitude: this.nowCity.latitude || '',
                    building: this.fieldValue || '',
                    Email: this.$store.state.userMsg.email || '',
                    DspName: this.$store.state.userMsg.dspName || '',//dspName
                }
                let eleH5Res = await this.GetHomePageReadOnlyUrl(eleH5params)
                this.openDianPing(eleH5Res.content)
                return
            }
            if(optionalType.includes('美团') && optionalType.length == 1){
                // 获取美团地址
                let meituanRes = await this.getDianPingUrl()
                this.openDianPing(meituanRes.content)
                return
            }
            if(
                optionalType.includes('美团') &&
                this.$route.query.webAppAlias == 'webapp-iwaimai')
            {
                // 保存数据
                let sessionRes = await this.saveCacheData({Data:appData})
                this.goWaimai(sessionRes)
            }else{
                this.$publicFun.openWebAppCate(appData, appConf);
            }
            this.backPage();
        },
        async openImeal(appData, appConf){
            let optionalType = await this.getMwalType()
            let sessionRes = await this.saveCacheData({Data:appData})
            let meituanUrl = '';
            let imealUrl = await this.$publicFun.openWebAppCate(appData, appConf,false,true);
            // 获取美团地址
            let meituanRes = await this.meituanLogin()
            if(meituanRes && meituanRes.success){
                meituanUrl = meituanRes.content
            }
            // imealUrl = `${this.$smartStorage.get("Uris")['webapp-imeal']}/ele/shopList?appType=iMeal&rnd=${new Date().getTime()}&sessionId=${sessionRes.content}`
            // await this.addEmployee()
            console.log('美团',meituanUrl);
            console.log('imealUrl',imealUrl);
            //TODO 餐聚合，暂时不动
            if (window.flutter_inappwebview) {
                let params = {
                    "url":"mealCombine",
                    "postData":{
                         "mealCombineData":[
                        {
                            selected: 'https://spkg.smartmice.cn/resources/images/imeal/jh_ele_gd_xms_focus.png',
                            normal: 'https://spkg.smartmice.cn/resources/images/imeal/jh_ele_gd_xms_blur.png',
                            url: imealUrl
                        },
                        {
                            selected: 'https://spkg.smartmice.cn/resources/images/imeal/wm_mt_focus.png',
                            normal: 'https://spkg.smartmice.cn/resources/images/imeal/wm_mt_blur.png',
                            url: meituanUrl
                        }
                    ],
                    }
                };

                let result = await window.flutter_inappwebview.callHandler("SmGeneral.openNewWebSite", JSON.stringify(params));
                console.log(result);
                //TODO 刷新微站？
            } else {
                const options = {
                method: "SMGeneral.openNewWebSiteForResult",
                postData:{
                    loadUrl: 'mealCombineData',
                    cookieData: this.$cookie.getAllCookies(),
                    mealCombineData:[
                        {
                            selected: 'https://spkg.smartmice.cn/resources/images/imeal/jh_ele_gd_xms_focus.png',
                            normal: 'https://spkg.smartmice.cn/resources/images/imeal/jh_ele_gd_xms_blur.png',
                            url: imealUrl
                        },
                        {
                            selected: 'https://spkg.smartmice.cn/resources/images/imeal/wm_mt_focus.png',
                            normal: 'https://spkg.smartmice.cn/resources/images/imeal/wm_mt_blur.png',
                            url: meituanUrl
                        }
                    ],
                },
                callback: () => {
                }
            }
            let IJsBridge = new iJsBridge({
                alert: function(e) {
                    console.log(e);
                },
            });
             IJsBridge.call(options);
            }
        },
        async openMartke(item){
            let res = await this.getMealRules()
            if(res.marketSupplier && res.marketSupplier.length == 1){
                switch (res.marketSupplier[0]) {
                    case 'JD':
                        this.goJD(res)
                        break;
                    case 'ELE':
                        this.goEle()
                        break;
                }
                return
            }
        },
        async handlechannel(itemType){
            let that = this
            switch (itemType) {
                case 'ELE':
                    if(this.isWx()){
                        this.goWxShow = true
                        this.$router.push({
                            path: '/applicationChooseCity',
                            query: {
                                ...this.$route.query,
                                fromWx: true,
                                address: JSON.stringify(this.nowCity),
                                sessionId: this.$smartStorage.get("sessionId")
                            },
                        })
                        this.$refs.itemPop.open();
                        return
                    }
                    var params = {
                        "ItemId": this.generateUUID(),
                        "Amount": 0.01,
                        "Sid": this.mealRules.sid,
                        "ItemType": 7
                    }
                    var elemeURL = await this.$service.common('/rpc/pay/Alipay/GenerateReadOnlyAliTakeoutUrl', params)
                    if(elemeURL && elemeURL.success){
                        window.location.href = elemeURL.content
                    }
                break;
                case "xiaomishu":
                    var appData = {
                        paramCode: {
                            city: this.fieldValue || '',
                            search: true,
                            latitude: this.nowCity.latitude || '',
                            longitude: this.nowCity.longitude || ''
                        },
                        isFromMore: true,
                        tenant_code: tinyCookie.getCookie("tenant_code"),
                        token: tinyCookie.getCookie("token"),
                    };
                    var appConf = {
                        webAppAlias: this.$route.query.webAppAlias,
                        path: this.$route.query.path,
                        params: `?appType=iMeal&rnd=${new Date().getTime()}&merchantsType=1&mcOnly=1&isQuery=true&action=order&appType=iMeal&noBack=false`
                    };
                    this.$publicFun.openWebAppCate(appData, appConf);
                    break
                default:
                    break;
            }
        },
        isWx() {
            var ua = navigator.userAgent.toLowerCase();
            if (/MicroMessenger/gi.test(ua)) {
                return true;
            } else {
                return false;
            }
        },
        goJD(res){
            let params = {
                itemId: this.$store.state.userMsg.phoneNumber,// this.memsg.phoneNumber
                itemType: '',// ''
                loginType: 1,// 1
                amount: 0, // 0
                userName: this.$store.state.userMsg.dspName,
                phone: this.$store.state.userMsg.phoneNumber,
                activityCode: res.activityCode,
            }
            this.$service.JDH5Login(params).then(res => {
                let content = res.content
                this.openPostWindow(content, '_self');
            })
        },
        async goEle(){
            let marketparams = {
                itemId: this.generateUUID(),
                UserPhone: this.$store.state.userMsg.phoneNumber,
                Longitude: this.nowCity.longitude || '',
                Latitude: this.nowCity.latitude || '',
                building: this.fieldValue || '',
                Email: this.$store.state.userMsg.email || '',
                DspName: this.$store.state.userMsg.dspName || '',//dspName
                ItemType: 20
            }
            let marketRes = await this.GetHomePageReadOnlyUrl(marketparams)
            const container = tinyCookie.get("container");
            switch (container) {
                case "browser":
                    this.$publicFun._browser(marketRes.content);
                    break;
                case "h5":
                    this.$publicFun._h5(marketRes.content);
                    break;
                default:
                    this.$publicFun.openDianPing(marketRes.content)
                    break;
            }
        },
        openPostWindow(data, name){
            let tempForm = document.createElement('form');
            tempForm.id = 'tempForm';
            tempForm.action = data.loginUrl;
            tempForm.method = 'post';
            tempForm.target = name;
            
            let accessKey = document.createElement('input');
            accessKey.type = 'hidden';
            accessKey.name = 'accessKey';
            accessKey.value = data.accessKey;
            tempForm.appendChild(accessKey);
            
            let extend = document.createElement('input');
            extend.type = 'hidden';
            extend.name = 'extend';
            extend.value = data.extend;
            tempForm.appendChild(extend);
            
            let returnUrl = document.createElement('input');
            returnUrl.type = 'hidden';
            returnUrl.name = 'returnUrl';
            returnUrl.value = data.returnUrl;
            tempForm.appendChild(returnUrl);
            
            let sign = document.createElement('input');
            sign.type = 'hidden';
            sign.name = 'sign';
            sign.value = data.sign;
            tempForm.appendChild(sign);
            
            let timestamp = document.createElement('input');
            timestamp.type = 'hidden';
            timestamp.name = 'timestamp';
            timestamp.value = data.timestamp;
            tempForm.appendChild(timestamp);
            
            let uid = document.createElement('input');
            uid.type = 'hidden';
            uid.name = 'uid';
            uid.value = data.uid;
            tempForm.appendChild(uid);
            
            let version = document.createElement('input');
            version.type = 'hidden';
            version.name = 'version';
            version.value = data.version;
            tempForm.appendChild(version);

            document.body.appendChild(tempForm);
            if (window.attachEvent) {
                tempForm.attachEvent('onsubmit', () => { window.open(data.loginUrl, name); });
            }
            if(tempForm.fireEvent){
                tempForm.fireEvent('onsubmit');
            }else{
                let evt = document.createEvent('HTMLEvents');
                evt.initEvent('onsubmit', true, true);
                tempForm.dispatchEvent(evt);
            }
            tempForm.submit();
            document.body.removeChild(tempForm);
        },
        openDianPing(url){
            const container = tinyCookie.get("container");
                switch (container) {
                    case "browser":
                        this.$publicFun._browser(url);
                        break;
                    case "h5":
                        this.$publicFun._h5(url);
                        break;
                    default:
                        this.$publicFun.openDianPing(url)
                        break;
                }
        },
        // 获取外出用餐类型
        getMwalType() {
            let params = {
                collection: "cfg-imeal-channel",
                useCaching: false,
                cachingKey: "",
                filter: {
                    tenantCode: tinyCookie.getCookie("tenant_code") || "crossevent",
                    mealType: "外出用餐"
                },
                projection: {
                    optionalType: 1,
                }
            };
            return new Promise((resolve, reject) => {
                this.$service.QuerySettings(params).then(res => {
                    if (res && res.success && res.content) {
                        resolve((res.content[0] || {optionalType:[]}).optionalType)
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        },
        // 获取外卖类型
        getWaimaiType() {
            let params = {
                collection: "cfg-imeal-channel",
                useCaching: false,
                cachingKey: "",
                filter: {
                    tenantCode: tinyCookie.getCookie("tenant_code") || "crossevent",
                    mealType: "外卖",
                    tenant_id: this.$smartStorage.get("tenant_id") || this.$cookie.get("tenant_id"),
                },
                projection: {
                    optionalType: 1,
                }
            };
            return new Promise((resolve, reject) => {
                this.$service.QuerySettings(params).then(res => {
                    if (res && res.success && res.content) {
                        resolve((res.content[0] || {optionalType:[]}).optionalType)
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        },
        async getMealRules(){
            let params = {
                collection: "cfg-imeal-mealrules",
                useCaching: false,
                cachingKey: "",
                filter: {
                    tenant_id: this.$smartStorage.get("tenant_id") || this.$cookie.get("tenant_id"),
                },
            };
            return new Promise((resolve, reject) => {
                this.$service.QuerySettings(params).then(res => {
                    if (res && res.success && res.content) {
                        console.log(res.content[0]);
                        resolve(res.content[0])
                    }else{
                        resolve({})
                    }
                }).catch(err => {
                    reject(false)
                })
            })
        },
        backPage() {
            this.$router.push({
                path: '/application'
            });
        },
        async goWaimai(sessionRes){
            let meituanUrl = '';
            let iwaimaiUrl = '';
            // 获取美团地址
            let meituanRes = await this.getDianPingUrl()
            if(meituanRes && meituanRes.success){
                meituanUrl = meituanRes.content
            }
            iwaimaiUrl = `${this.$smartStorage.get("Uris")['webapp-iwaimai']}/ele/shopList?appType=iMeal&rnd=${new Date().getTime()}&sessionId=${sessionRes.content}`
            // await this.addEmployee()
            //TODO 餐聚合，暂时不动
            if (window.flutter_inappwebview) {
                let params = {
                    "url":"mealCombine",
                    "postData":{
                         "mealCombineData":[
                        {
                            selected: 'https://spkg.smartmice.cn/resources/images/imeal/ele_focus.png',
                            normal: 'https://spkg.smartmice.cn/resources/images/imeal/ele_blur.png',
                            url: iwaimaiUrl
                        },
                        {
                            selected: 'https://spkg.smartmice.cn/resources/images/imeal/wm_mt_focus.png',
                            normal: 'https://spkg.smartmice.cn/resources/images/imeal/wm_mt_blur.png',
                            url: meituanUrl
                        }
                    ],
                    }
                };

                let result = await window.flutter_inappwebview.callHandler("SmGeneral.openNewWebSite", JSON.stringify(params));
                console.log(result);
                //TODO 刷新微站？
                
            } else {
                const options = {
                method: "SMGeneral.openNewWebSiteForResult",
                postData:{
                    loadUrl: 'mealCombineData',
                    cookieData: this.$cookie.getAllCookies(),
                    mealCombineData:[
                        {
                            selected: 'https://spkg.smartmice.cn/resources/images/imeal/ele_focus.png',
                            normal: 'https://spkg.smartmice.cn/resources/images/imeal/ele_blur.png',
                            url: iwaimaiUrl
                        },
                        {
                            selected: 'https://spkg.smartmice.cn/resources/images/imeal/wm_mt_focus.png',
                            normal: 'https://spkg.smartmice.cn/resources/images/imeal/wm_mt_blur.png',
                            url: meituanUrl
                        }
                    ],
                },
                callback: () => {
                }
            }
            let IJsBridge = new iJsBridge({
                alert: function(e) {
                    console.log(e);
                },
            });
             IJsBridge.call(options);
            }
        },
        GetHomePageReadOnlyUrl(params){
            return new Promise((resolve, reject) => {
                this.$service.GetHomePageReadOnlyUrl(params).then(res => {
                    if(res && res.success){
                        resolve(res)
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        },
        meituanLogin(){
            return new Promise((resolve, reject) => {
                let  params = {
                    itemId: this.generateUUID(),
                    latitude: this.nowCity.latitude || '',
                    longitude: this.nowCity.longitude || ''
                }
                this.$service.meituanLogin(params).then(res => {
                    if(res && res.success){
                        resolve(res)
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        },
        getDianPingUrl(){
            return new Promise((resolve, reject) => {
                this.$service.getDianPingUrl({itemId: this.generateUUID()}).then(res => {
                    if(res && res.success){
                        resolve(res)
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        },
		generateUUID() {
			return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
				var r = Math.random() * 16 | 0,
					v = c == 'x' ? r : (r & 0x3 | 0x8);
				return v.toString(16);
			});
		},
        saveCacheData(params){
            return new Promise((resolve,reject) => {
                this.$service.saveCacheData(params).then(sessionRes => {
                    if(sessionRes && sessionRes.success){
                        resolve(sessionRes)
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        },
        addEmployee(){
            return new Promise((resolve,reject) => {
                this.$service.addEmployee({SourceCode: "ELE"}).then(res => {
                    if(res && res.success){
                        resolve(res)
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        },
    },
};
</script>

<style lang="less" scoped>
/deep/ .shopSearchRow input{
    border-radius: .05rem!important;
}
/deep/ .van-cell {
    padding: 10px 0.25rem;
}

/deep/ .van-cell:after {
    border-bottom: none;
}

/deep/ .header-bg {
    opacity: unset;
}

/deep/ .h-center {
    color: #ffffff;
}

.cnt-box {
    .SplitLine {
        height: 0.1rem;
        background: #fafafa;
    }
}

.appChooseCityBtn {
    background: #fff;
    margin: 0.3rem;
    box-shadow: 1px -0.15rem 0.15rem #fff;
}
        .WXShadow {
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.3);
            position: fixed;
            top: 40px;
            z-index: 3;
            img {
                width: 36%;
                height: 25%;
                float: right;
                margin-right: 0.2rem;
                margin-top: 0.2rem;
            }
            .shadowtext {
                font-size: 0.2rem;
                position: fixed;
                top: 2rem;
                width: 80%;
                background: #fff;
                left: 5%;
                text-align: center;
                padding: 0.15rem;
                padding-bottom: 0.25rem;
                border-radius: 0.1rem;
                box-shadow: 0 0 9px 0px #2a292966;
            div {
                font-weight: 600;
                margin-bottom: 0.2rem;
            }
            span {
                font-size: 0.16rem;
            }
            a {
                display: block;
                width: 60%;
                font-size: 0.16rem;
                background: var(--themeColor);
                color: #fff;
                padding: 0.12rem 0.48rem;
                border-radius: 0.05rem;
                margin: 0 auto;
                margin-top: 0.5rem;
            }
            }
        }
</style>
